<template>
  <dashPage no-side v-if="$store.state.auth.userType === 'superadmin'">
    <h1>Translations</h1>
    <v-simple-table  dense>
      <tbody>
      <tr v-for="key in keys" :key="key">
        <td> <h5>{{  key  }}</h5></td>
        <td width="400"> <v-text-field hide-details outlined dense v-model="locale_ua[key]" /> </td>
        <td width="400"> <v-text-field hide-details outlined dense v-model="locale_ru[key]" /></td>
        <td width="400"> <v-text-field hide-details outlined dense v-model="locale_en[key]" /></td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th colspan="4">
          <v-text-field v-model="newKey" />
          <v-btn block dark :color="wsDARKLIGHT" @click="keys.push(newKey); newKey = ''" >Додати переклад </v-btn>
        </th>
      </tr>
      </tfoot>
    </v-simple-table>
    <v-btn block dark class="mt-3" :color="wsDARKLIGHT" @click="saveChanges('ua')" >Записати UA</v-btn>
    <v-btn block dark class="mt-3" :color="wsDARKLIGHT" @click="saveChanges('en')" >Записати EN</v-btn>
    <v-btn block dark class="mt-3" :color="wsDARKLIGHT" @click="saveChanges('ru')" >Записати RU</v-btn>
  </dashPage>
</template>

<script>


import locale_en from "@/locales/en.json";
import locale_ru from "@/locales/ru.json";
import locale_ua from "@/locales/ua.json";
export default {
  name: "DashTranslations",
  data() {
    return  {
      locale_en : locale_en,
      locale_ru : locale_ru,
      locale_ua : locale_ua,
      locales : {},
      keys : [],
      newKey : ''
    }
  },
  methods : {
    saveChanges(lang) {
      let data = ''
      switch (lang) {
        case 'ua' : data = JSON.stringify(locale_ua);  break;
        case 'en' : data = JSON.stringify(locale_en);  break;
        case 'ru' : data = JSON.stringify(locale_ru);  break;
      }


      let blob = new Blob([data], {type: 'text/plain'})

      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = lang + ".json";
      document.body.appendChild(a);
      a.click();
      a.remove();

    }
  },
  mounted() {
    Object.keys(this.locale_ua).forEach((key)=>{
     if ( !this.keys.includes(key)) {
       this.keys.push(key)
     }
    })
    Object.keys(this.locale_ru).forEach((key)=>{
      if ( !this.keys.includes(key)) {
        this.keys.push(key)
      }
    })
    Object.keys(this.locale_en).forEach((key)=>{
      if ( !this.keys.includes(key)) {
        this.keys.push(key)
      }
    })
  }
}
</script>

<style scoped>

</style>